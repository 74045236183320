<form [formGroup]="cardPaymentForm">
    <section id="new-card-payment">
        <div class="col-12">
            <seech-input formControlName="fullName" label="Enter Full Name On Card" id="card-name"
                placeholder="Name on your card" sch-validations />
        </div>

        <div class="col-12">
            <seech-input formControlName="cardNumber" label="Enter Card Number" id="card-number"
                placeholder="Enter your card number" (input)="onCardNumberChange()" (change)="validateCardNumber()"
                sch-validations />

            <seech-validation *ngIf="!cardNumberValid" type="error" [message]="getErrorMessage('cardNumber')" />
        </div>

        <div class="col-12">
            <seech-select formControlName="cardBrand" [id]="'card-brand'" [options]="cardBrand"
                [placeholder]="'Choose card brand'" [label]="'Card Brand'" (selected)="handleSelectedCardBrand($event)"
                (reachedEndOfList)="handleCardBrandPagination()" sch-validations />

            <seech-validation *ngIf="!cardBrandValid" type="error" [message]="cardBrandErrorMessage" />
        </div>

        <div class="col-12 options-wrapper">
            <ng-container *ngFor="let type of cardTypes; let i = index">
                <div sch-radio [label]="type.label" [id]="'card-type' + i" shape="circle">
                    <input type="radio" formControlName="cardType" [value]="type.value">
                </div>
            </ng-container>
        </div>

        <div class="col-12 bottom-items">
            <div>
                <seech-expiration-date formControlName="expirationDate" [(value)]="selectedDate"
                    [label]="'Expiration Date'" [id]="'card-expDate'" [placeholder]="'Select a Date'" sch-validations />
            </div>

            <div>
                <seech-password formControlName="cvv" label="CVV" id="card-cvv" placeholder="Enter CVV"
                    [maxLength]="chosenCardCvvLength" (change)="validateCvv()" sch-validations />

                <seech-validation *ngIf="!cvvValid" type="error" [message]="getErrorMessage('cvv')" />
            </div>
        </div>
    </section>
</form>