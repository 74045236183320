import { Injectable } from '@angular/core';
import { AccountService } from '../generated';

@Injectable({
  providedIn: 'root',
})
export class AccountClientService {
  constructor(private accountService: AccountService) { }

  /**
   * Logs In the User.
   *
   * @returns An Observable that emits the object of the logged in user.
   */
  userAccountLogin() {
    return this.accountService.accountLogin();
  }
}
