import { Injectable } from '@angular/core';
import {
  BankAccountService,
  BankAccountCreateRequestModel,
  BankAccountUpdateRequestModel,
} from '../generated';

@Injectable({
  providedIn: 'root',
})
export class BankAccountClientService {
  constructor(private bankAccountService: BankAccountService) {}

  /**
   * Retrieves the list of bank accounts created.
   *
   * @returns An Observable that emits the list of bank accounts of a user.
   */
  getBankAccounts() {
    return this.bankAccountService.bankAccountGetBankAccounts();
  }

  /**
   * Adds a new bank account.
   * @param bank model of the bank properties being added.
   * @returns returns the data observable.
   */
  addBankAccount(bank: BankAccountCreateRequestModel) {
    return this.bankAccountService.bankAccountAddBankAccount(bank);
  }

  /**
   * Retrieves the config.
   * @param countryCode.
   * @returns An Observable .
   */
  getBankAccountConfig(countryCode: string) {
    return this.bankAccountService.bankAccountGetBankConfig(countryCode);
  }

  /**
   * Update an existing bank account.
   * @param bankAccount model of the bank account properties being updated.
   * @returns returns the data observable.
   */
  updateBankAccount(bankAccount: BankAccountUpdateRequestModel) {
    return this.bankAccountService.bankAccountUpdateBankAccount(bankAccount);
  }

  /**
   * Deletes a bank account.
   * @param id id of the bank account being deleted.
   * @returns returns the data observable.
   */
  deleteBankAccount(id: string) {
    return this.bankAccountService.bankAccountDeleteBankAccount(id);
  }
}
