import { Injectable } from '@angular/core';
import { paymentMethods, quotes } from '../mock-data/mock';
import { of } from 'rxjs';
import { LookupService } from '../generated';

@Injectable({
  providedIn: 'root',
})
export class LookupClientService {
  constructor(private client: LookupService) {}

  getPaymentMethods() {
    return of(paymentMethods);
  }

  getQuotes() {
    return of(quotes);
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCardBrands() {
    return this.client.lookupGetCardBrands();
  }

  /**
   * @param keyword
   * @param page
   * @param size
   * @param requiredItems
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCountries(
    keyword?: string,
    page?: number,
    size?: number,
    requiredItems?: string
  ) {
    return this.client.lookupGetCountriesWithPhoneCodes(
      keyword,
      page,
      size,
      requiredItems
    );
  }

  /**
   * @param keyword
   * @param page
   * @param size
   * @param requiredItems
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencies(
    keyword?: string,
    page?: number,
    size?: number,
    requiredItems?: string
  ) {
    return this.client.lookupGetCurrencies(keyword, page, size, requiredItems);
  }

  /**
   * @param currencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencyByCurrencyCode(currencyCode: string) {
    return this.client.lookupGetCurrencyByCurrencyCode(currencyCode);
  }

  /**
   * @param countryCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencyByCountryCode(countryCode: string) {
    return this.client.lookupGetCurrencyByCountryCode(countryCode);
  }
}
