export enum BankCardTypes {
  C = 'Credit Card',
  D = 'Debit Card',
  P = 'Prepaid Card',
  V = 'Virtual Card',
}

export const BankCardTypeLabels: any[] = [
  {
    label: 'Debit',
    value: 'D',
  },
  { label: 'Credit', value: 'C' },
  { label: 'Prepaid', value: 'P' },
  { label: 'Virtual', value: 'V' },
];
