import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlsNgModule, CheckboxDirective } from '@seech/controls-ng';
import { NewBankPaymentComponent } from './new-bank-payment.component';

@NgModule({
  declarations: [NewBankPaymentComponent],
  imports: [
    CommonModule,
    FormsModule,
    ControlsNgModule,
    CheckboxDirective,
    ReactiveFormsModule,
  ],
  exports: [NewBankPaymentComponent],
})
export class NewBankPaymentModule {}
