import { Injectable } from '@angular/core';
import { LookupClientService } from '../clients/lookup-client.service';
import { BehaviorSubject, Observable } from 'rxjs';

export interface TransformedCardBrand {
  name: string;
  cardNumberRegex: RegExp;
  cvvRegex: RegExp;
  cvvLength: number;
  cardNumberLengths: Array<number>;
}

@Injectable({
  providedIn: 'root',
})
export class CardValidationService {
  private cardBrands: TransformedCardBrand[] = [];
  private cardBrandsSubject = new BehaviorSubject<TransformedCardBrand[]>([]);

  constructor(private lookupService: LookupClientService) {
    this.loadCardBrands();
  }

  private loadCardBrands(): void {
    this.lookupService.getCardBrands().subscribe((brands) => {
      this.cardBrands = brands.map((brand) => ({
        ...brand,
        cardNumberRegex: new RegExp(brand.cardNumberRegex),
        cvvRegex: new RegExp(brand.cvvRegex),
      }));
      this.cardBrandsSubject.next(this.cardBrands);
    });
  }

  getCardBrands(): Observable<TransformedCardBrand[]> {
    return this.cardBrandsSubject.asObservable();
  }

  // Identify the card brand based on card number
  identifyCardBrand(cardNumber: string): TransformedCardBrand | null {
    return (
      this.cardBrands.find((brand) => brand.cardNumberRegex.test(cardNumber)) ||
      null
    );
  }

  // Validate card number
  validateCardNumber(cardNumber: string): boolean {
    const brand = this.identifyCardBrand(cardNumber);
    if (!brand) return false;
    return brand.cardNumberLengths.includes(cardNumber.length);
  }

  // Validate CVV
  validateCvv(cvv: string, cardNumber: string): boolean {
    const brand = this.identifyCardBrand(cardNumber);
    if (!brand) return false;
    return brand.cvvRegex.test(cvv) && cvv.length === brand.cvvLength;
  }

  // Wrapper method to validate both card number and CVV
  validateCard(cardNumber: string, cvv: string): boolean {
    return (
      this.validateCardNumber(cardNumber) && this.validateCvv(cvv, cardNumber)
    );
  }
}
