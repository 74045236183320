import { PaymentMethodMapping } from '../constants';
import { CardBrand } from '../models';

export const userSubscriptionDetails = [
  {
    id: '1',
    image: `sample-sub-image.png`,
    name: 'Premium Subscription',
    description: 'Access to all premium features.',
    redactedPaymentMethod: '**** **** **** 1234',
    billingCycleInDays: 30,
    contractStartDate: new Date('2023-01-01'),
    contractEndDate: new Date('2024-01-01'),
    nextPaymentDate: new Date('2024-01-01'),
    price: 49.99,
    status: 'Active',
    statusMessage: 'Subscription is currently active.',
    isAutoRenewed: true,
    isAutoPaid: true,
  },
  {
    id: '2',
    image: `sample-sub-image.png`,
    name: 'Basic Subscription',
    description: 'Limited access to basic features.',
    redactedPaymentMethod: '**** **** **** 5678',
    billingCycleInDays: 30,
    contractStartDate: new Date('2022-10-01'),
    contractEndDate: new Date('2023-10-01'),
    nextPaymentDate: new Date('2023-09-30'),
    price: 9.99,
    status: 'Active',
    statusMessage: 'Subscription is currently active.',
    pausedUntilDate: new Date('2023-12-01'),
    isAutoRenewed: false,
    isAutoPaid: true,
  },
  {
    id: '3',
    image: `sample-sub-image.png`,
    name: 'Annual Plan',
    description: 'One-time payment for a full year of service.',
    redactedPaymentMethod: '**** **** **** 8765',
    billingCycleInDays: 365,
    contractStartDate: new Date('2023-05-01'),
    contractEndDate: new Date('2024-05-01'),
    nextPaymentDate: new Date('2024-05-01'),
    price: 120.0,
    status: 'Paused',
    statusMessage: 'Subscription is paused until December 2023.',
    pausedUntilDate: new Date('2023-12-31'),
    isAutoRenewed: true,
    isAutoPaid: false,
  },
  {
    id: '4',
    image: `sample-sub-image.png`,
    name: 'Student Plan',
    description: 'Discounted plan for students.',
    redactedPaymentMethod: '**** **** **** 4321',
    billingCycleInDays: 180,
    contractStartDate: new Date('2022-08-15'),
    contractEndDate: new Date('2023-08-15'),
    nextPaymentDate: new Date('2023-08-15'),
    price: 19.99,
    status: 'Cancelled',
    statusMessage: 'Subscription has been cancelled.',
    cancelledOn: new Date('2023-07-01'),
    isAutoRenewed: false,
    isAutoPaid: false,
  },
  {
    id: '5',
    image: `sample-sub-image.png`,
    name: 'Family Plan',
    description: 'Subscription for up to 5 family members.',
    redactedPaymentMethod: '**** **** **** 8765',
    billingCycleInDays: 30,
    contractStartDate: new Date('2023-02-01'),
    contractEndDate: new Date('2023-12-31'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 39.99,
    status: 'Active',
    statusMessage: 'Subscription is active and auto-renews monthly.',
    isAutoRenewed: true,
    isAutoPaid: true,
  },
  {
    id: '6',
    image: `sample-sub-image.png`,
    name: 'Corporate Plan',
    description: 'Enterprise subscription for corporate users.',
    redactedPaymentMethod: '**** **** **** 1122',
    billingCycleInDays: 90,
    contractStartDate: new Date('2023-03-01'),
    contractEndDate: new Date('2024-03-01'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 299.99,
    status: 'Paid Off',
    statusMessage: 'Subscription fully paid for the contract period.',
    paidOffOn: new Date('2023-03-01'),
    isAutoRenewed: false,
    isAutoPaid: true,
  },
  {
    id: '7',
    image: `sample-sub-image.png`,
    name: 'Mega Plan',
    description: 'Enterprise subscription for mega users.',
    redactedPaymentMethod: '**** **** **** 1122',
    billingCycleInDays: 120,
    contractStartDate: new Date('2023-03-01'),
    contractEndDate: new Date('2024-03-01'),
    nextPaymentDate: new Date('2023-12-01'),
    price: 499.99,
    status: 'Expired',
    statusMessage: 'Subscription has expired.',
    paidOffOn: new Date('2023-03-01'),
    isAutoRenewed: true,
    isAutoPaid: false,
  },
];

export const quotes = {
  name: 'Online Subscription',
  description: 'Authorization for recurring subscription payment.',
  additionalInfo: {
    invoiceId: 'INV001',
    service: 'Streaming',
    plan: 'Premium',
  },
  acceptAnyAmount: true,
  allowScheduling: true,
  supportedPaymentMethods: [
    {
      id: '1',
      name: PaymentMethodMapping.bankCard.name,
      description:
        'credit cards for online and in-store payments or debit card linked to your bank account',
      imageUrl: `card-wallet.svg`,
    },
    {
      id: '2',
      name: PaymentMethodMapping.bankAccount.name,
      description: 'Direct bank debit for secure payments from your account',
      imageUrl: `bank-image.svg`,
    },
    {
      id: '3',
      name: PaymentMethodMapping.paypal.name,
      description: 'Secure online payment method using your PayPal balance',
      imageUrl: `paypal.svg`,
    },
    {
      id: '4',
      name: PaymentMethodMapping.venmo.name,
      description: 'Secure online payment method using your Venmo balance',
      imageUrl: `venmo.svg`,
    },
  ],
  savedPaymentMethods: [
    { id: 'spm1', type: 'creditCard', name: 'My Visa Card' },
    { id: 'spm2', type: 'wallet', name: 'My Digital Wallet' },
  ],
  payments: [
    {
      amount: 49.99,
      description: 'Monthly Subscription Fee',
      dueDate: new Date('2024-12-01'),
    },
    {
      amount: 149.99,
      description: 'Quarterly Subscription Fee',
      dueDate: new Date('2024-03-01'),
    },
  ],
};
// {
//   name: 'Quote for Project Alpha',
//   description: 'Initial phase development for Project Alpha',
//   invoiceId: 'INV001',
//   acceptAnyAmount: false,
//   payments: [
//     { amount: 1500, dueDate: new Date('2024-12-01') },
//     { amount: 2000, dueDate: new Date('2025-01-15') },
//   ],
// };

export const paymentMethods = [
  {
    id: '1',
    name: PaymentMethodMapping.bankCard.name,
    description:
      'credit cards for online and in-store payments or debit card linked to your bank account',
    imageUrl: `card-wallet.svg`,
  },
  {
    id: '2',
    name: PaymentMethodMapping.bankAccount.name,
    description: 'Direct bank debit for secure payments from your account',
    imageUrl: `bank-image.svg`,
  },
  {
    id: '3',
    name: PaymentMethodMapping.paypal.name,
    description: 'Secure online payment method using your PayPal balance',
    imageUrl: `paypal.svg`,
  },
  {
    id: '4',
    name: PaymentMethodMapping.venmo.name,
    description: 'Secure online payment method using your Venmo balance',
    imageUrl: `venmo.svg`,
  },
];

export const cardBrands: CardBrand[] = [
  {
    name: 'Visa',
    cardNumberRegex: '^4[0-9]{12}(?:[0-9]{3})?$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [13, 16],
  },
  {
    name: 'MasterCard',
    cardNumberRegex: '^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'American Express',
    cardNumberRegex: '^3[47][0-9]{13}$',
    cvvRegex: '^[0-9]{4}$',
    cvvLength: 4,
    cardNumberLengths: [15],
  },
  {
    name: 'Discover',
    cardNumberRegex: '^6(?:011|5[0-9]{2})[0-9]{12}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'JCB',
    // cardNumberRegex: '/^(?:2131|1800|35d{3})d{11}$/',
    // cvvRegex: '/^d{3}$/',
    cardNumberRegex: '^(?:2131|1800|35\\d{3})\\d{11}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'Diners Club',
    cardNumberRegex: '^3(?:0[0-5]|[68][0-9])[0-9]{11}$',
    cvvRegex: '^[0-9]{3}$',
    cvvLength: 3,
    cardNumberLengths: [14],
  },
  {
    name: 'UnionPay',
    cardNumberRegex: '^62[0-9]{14,17}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16, 17, 18, 19],
  },
  {
    name: 'RuPay',
    cardNumberRegex: '^6[0-9]{15}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
  {
    name: 'Troy',
    cardNumberRegex: '^9[0-9]{15}$',
    cvvRegex: '/^d{3}$/',
    cvvLength: 3,
    cardNumberLengths: [16],
  },
];
