import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { BankAccount, BankAccountResponse } from '../../../models';
import { LookupClientService } from '../../../clients/lookup-client.service';
import { VALIDATION } from '../../../constants';
import { BankAccountClientService } from '../../../clients';

@Component({
  selector: 'seech-new-bank-payment',
  templateUrl: './new-bank-payment.component.html',
  styleUrl: './new-bank-payment.component.scss',
})
export class NewBankPaymentComponent implements OnChanges, OnInit, OnDestroy {
  newBankPaymentForm!: FormGroup;
  bankAccounts: BankAccount[] = [];
  countries: any[] = [];
  bankAccountConfig: any = {};
  isEditing!: boolean;

  pageNumber = 0;
  paginationSize = 50;
  isPaginating = false;
  searchedValue!: string;

  @Input() editableBankItem!: BankAccountResponse;
  @Input() showRequiredFieldErrors = false;
  @Output() formValuesChanged = new EventEmitter<any>();

  private countrySearchTerms$ = new Subject<string>();
  private subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupClientService,
    private bankAccountService: BankAccountClientService
  ) {}

  ngOnInit(): void {
    this.buildBankPaymentForm();
    this.loadCountries();

    this.subscription.add(
      this.countrySearchTerms$.pipe(debounceTime(300)).subscribe((value) => {
        this.searchedValue = value || '';
        this.pageNumber = 0; // Reset page number when searching
        this.cdr.detectChanges();
        this.loadCountries();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editableBankItem'] && this.editableBankItem) {
      this.isEditing = true;
      this.buildBankPaymentForm();
      this.handleCountryValueChanged({
        value: this.editableBankItem.countryCode,
      });
    }
    if (changes['showRequiredFieldErrors'] && this.showRequiredFieldErrors) {
      this.revealValidationErrors();
    }
  }

  buildBankPaymentForm() {
    this.newBankPaymentForm = this.fb.group({
      country: [
        this.editableBankItem?.countryCode || '',
        VALIDATION.countryValidator,
      ],
      fullName: [
        this.editableBankItem?.accountHolderName || '',
        VALIDATION.accountNameValidator,
      ],
      bankName: [
        this.editableBankItem?.bankName || '',
        VALIDATION.bankNameValidator,
      ],
    });

    if (this.editableBankItem) {
      this.formValuesChanged.emit(this.newBankPaymentForm.value);
    }

    // Emit form values on changes
    this.newBankPaymentForm.valueChanges.subscribe(() => {
      if (this.newBankPaymentForm.valid) {
        this.emitFormValues();
      }
    });
  }

  loadCountries() {
    this.subscription.add(
      this.lookupService
        .getCountries(this.searchedValue, this.pageNumber, this.paginationSize)
        .subscribe((count) => {
          const mappedCountries = count.map((country) => ({
            ...country,
            label: `${country.countryName} - (${country.countryCode})`,
            value: `${country.countryCode}`,
          }));

          this.countries =
            this.pageNumber === 0
              ? mappedCountries
              : [...this.countries, ...mappedCountries];

          // Check if editableBankItem country exists in the loaded data
          if (this.editableBankItem?.countryCode) {
            const selectedCountry = this.editableBankItem.countryCode;
            const countryExists = this.countries.some(
              (country) => country.value === selectedCountry
            );
            if (!countryExists) {
              this.onSearchValueChange(selectedCountry);
            }
          }

          this.isPaginating = false;
          this.cdr.detectChanges();
        })
    );
  }

  onSearchValueChange(event: any) {
    this.countrySearchTerms$.next(event);
  }

  handleCountryPagination(): void {
    if (this.isPaginating) return;
    this.isPaginating = true;
    this.pageNumber += 1;
    this.loadCountries();
  }

  handleCountryValueChanged(event: any) {
    const newCountryValue = event.value;

    this.bankAccountService
      .getBankAccountConfig(newCountryValue)
      .subscribe((resData) => {
        if (resData) {
          this.bankAccountConfig = resData;
          this.updateBankAccountFormControls();
        }
      });

    this.newBankPaymentForm.patchValue({ country: newCountryValue });
    this.cdr.detectChanges();
  }

  private updateBankAccountFormControls(): void {
    const config = this.bankAccountConfig;

    if (config.accountNumber) {
      this.newBankPaymentForm.addControl(
        'accountNumber',
        this.fb.control(
          this.editableBankItem?.accountNumber.value || '',
          this.generateValidators(config?.accountNumber)
        )
      );
    } else {
      this.newBankPaymentForm.removeControl('accountNumber');
    }

    // Dynamically add form controls for internationalBankId and localBankId
    if (config.internationalBankId) {
      this.newBankPaymentForm.addControl(
        'internationalBankId',
        this.fb.control(
          this.editableBankItem?.internationalBankId?.value || '',
          this.generateValidators(config?.internationalBankId)
        )
      );
    } else {
      this.newBankPaymentForm.removeControl('internationalBankId');
    }

    if (config.localBankId) {
      this.newBankPaymentForm.addControl(
        'localBankId',
        this.fb.control(
          this.editableBankItem?.localBankId.value || '',
          this.generateValidators(config.localBankId)
        )
      );
    } else {
      this.newBankPaymentForm.removeControl('localBankId');
    }

    this.cdr.detectChanges();

    // Trigger validation if showRequiredFieldErrors is already true
    if (this.showRequiredFieldErrors) {
      this.revealValidationErrors();
    }
  }

  emitFormValues() {
    this.formValuesChanged.emit(this.newBankPaymentForm.value);
  }

  revealValidationErrors(): void {
    Object.keys(this.newBankPaymentForm.controls).forEach((controlName) => {
      this.newBankPaymentForm.get(controlName)?.markAsTouched();
      this.newBankPaymentForm.get(controlName)?.updateValueAndValidity();
    });
  }

  private generateValidators(config: any) {
    const validators = [];

    // Required Validator
    if (config.required) {
      validators.push((control: AbstractControl): ValidationErrors | null => {
        return control.value
          ? null
          : {
              requiredError: {
                message: `${config.title} is required.`,
                severity: 'error',
              },
            };
      });
    }

    // Pattern Validator
    if (config.regex) {
      validators.push((control: AbstractControl): ValidationErrors | null => {
        const regex = new RegExp(config.regex);
        return regex.test(control.value)
          ? null
          : {
              patternError: {
                message: config.message || `Invalid ${config.title} format.`,
                severity: 'error',
              },
            };
      });
    }

    return validators;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
