import { AbstractControl, ValidationErrors } from '@angular/forms';

export const VALIDATION = {
  nameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your full name',
            severity: 'error',
          },
        },

  cardNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter card number',
            severity: 'error',
          },
        },

  cardBrandValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please select a card brand',
            severity: 'error',
          },
        },

  // dateValidator: (control: AbstractControl): ValidationErrors | null =>
  //   control.value
  //     ? null
  //     : {
  //         requiredError: {
  //           message: 'Please enter a date',
  //           severity: 'error',
  //         },
  //       },

  dateValidator: (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // If no value is provided, return the required error
    if (!value) {
      return {
        requiredError: {
          message: 'Please enter a date',
          severity: 'error',
        },
      };
    }

    // Validate the date format
    const validFormat = /^(?:\d{2}\/\d{2}|\d{2}\/\d{4}|\d{4}\/\d{2})$/; // Supports mm/yy, mm/yyyy, and yyyy/mm formats
    if (!validFormat.test(value)) {
      return {
        invalidDateError: {
          message: 'Invalid date format',
          severity: 'error',
        },
      };
    }

    // Split the input to extract month and year
    const parts = value.split('/');
    let month: number | undefined;
    let year: number | undefined;

    if (value.length === 5) {
      // MM/YY
      month = parseInt(parts[0], 10);
      year = 2000 + parseInt(parts[1], 10); // Assume YY is in 2000s
    } else if (value.length === 7) {
      // MM/YYYY or YYYY/MM
      if (parts[0].length === 4) {
        year = parseInt(parts[0], 10);
        month = parseInt(parts[1], 10);
      } else {
        month = parseInt(parts[0], 10);
        year = parseInt(parts[1], 10);
      }
    }

    // Ensure month and year are defined before further validation
    if (month === undefined || year === undefined) {
      return {
        invalidDateError: {
          message: 'Please enter a valid date.',
          severity: 'error',
        },
      };
    }

    // Validate the month range (1-12)
    if (month < 1 || month > 12) {
      return {
        invalidDateError: {
          message: 'Please enter a valid month (01-12).',
          severity: 'error',
        },
      };
    }

    // Validate that the year is not in the past
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return {
        invalidDateError: {
          message: 'Date cannot be in the past.',
          severity: 'error',
        },
      };
    }

    return null;
  },

  cvvValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter CVV',
            severity: 'error',
          },
        },

  countryValidator: (control: AbstractControl): ValidationErrors | null => {
    const valid = control.value;
    return valid
      ? null
      : {
          requiredError: {
            message: 'Please select country',
            severity: 'error',
          },
        };
  },

  accountNameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your name',
            severity: 'error',
          },
        },

  accountNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your account number',
            severity: 'error',
          },
        },

  bankNameValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your bank name',
            severity: 'error',
          },
        },

  swiftSortNumberValidator: (
    control: AbstractControl
  ): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your swift/sort number',
            severity: 'error',
          },
        },

  routingNumberValidator: (control: AbstractControl): ValidationErrors | null =>
    control.value
      ? null
      : {
          requiredError: {
            message: 'Please enter your routing number',
            severity: 'error',
          },
        },
};
