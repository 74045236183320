import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { DatePipe } from '@seech/core-ng';
import {
  CheckboxDirective,
  ControlsNgModule,
  NestedSelectOption,
  RadioDirective,
} from '@seech/controls-ng';
import { IconsNgModule } from '@seech/icons-ng';
import { FormsModule } from '@angular/forms';
import { NewCardPaymentComponent } from './new-card-payment/new-card-payment.component';
import { NewBankPaymentModule } from './new-bank-payment/new-bank-payment.module';
import { PaymentMethodMapping } from '../../constants';
import { LookupClientService } from '../../clients/lookup-client.service';
import { ModalService, UxNgModule } from '@seech/ux-ng';

@Component({
  selector: 'seech-payment-authorization',
  standalone: true,
  imports: [
    CommonModule,
    DatePipe,
    ControlsNgModule,
    IconsNgModule,
    RadioDirective,
    FormsModule,
    NewCardPaymentComponent,
    NewBankPaymentModule,
    CheckboxDirective,
    UxNgModule,
  ],
  templateUrl: './payment-authorization.component.html',
  styleUrl: './payment-authorization.component.scss',
})
export class PaymentAuthorizationComponent implements OnInit, OnDestroy {
  subData!: any;
  newPaymentMethod: any;
  existingPaymentMethods: NestedSelectOption[] = [];
  selectedPaymentMethod: any;
  selectedAmountToPay!: string | number;
  otherAmountSelected = false;
  customAmount!: number; // For storing custom input amount if "Other Amount" is selected
  title = '';

  saveForCheckout = false;
  defaultPayment = false;

  selectedDate: any = '';
  minDate!: Date;

  authPaymentMode = PaymentMethodMapping;
  modeOfPayment = this.authPaymentMode.default.value;

  paymentQuote: any;
  paymentMethods: any[] = [];

  @Output() emitData: EventEmitter<any> = new EventEmitter();

  private subscription = new Subscription();

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private lookupService: LookupClientService
  ) {
    const currentDate = new Date();
    this.minDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.modalService.modalRefComponent().subscribe({
        next: (component: any) => {
          this.title = component.title;
          this.subData = component.data;
          this.cdr.detectChanges();
        },
      })
    );

    this.subscription.add(
      this.lookupService.getQuotes().subscribe((quotes) => {
        this.paymentQuote = quotes;
        this.loadPaymentMethods();
      })
    );

    this.loadExistingPaymentMethods();
  }

  onDropdownClicked(value: string) {
    this.saveForCheckout = false;
    this.defaultPayment = false;

    switch (value) {
      case this.authPaymentMode.bankCard.value:
        this.modeOfPayment = value;
        break;
      case this.authPaymentMode.bankAccount.value:
        this.modeOfPayment = value;
        break;
      default:
        break;
    }
  }

  loadPaymentMethods() {
    // this.lookupService.getPaymentMethods().subscribe((methods) => {
    this.paymentMethods = this.paymentQuote.supportedPaymentMethods.map(
      (met: any) => {
        let paymentModeValue: string;

        switch (met.name) {
          case PaymentMethodMapping.bankCard.name:
            paymentModeValue = this.authPaymentMode.bankCard.value;
            break;
          case PaymentMethodMapping.bankAccount.name:
            paymentModeValue = this.authPaymentMode.bankAccount.value;
            break;
          case PaymentMethodMapping.paypal.name:
            paymentModeValue = this.authPaymentMode.paypal.value;
            break;
          case PaymentMethodMapping.venmo.name:
            paymentModeValue = this.authPaymentMode.venmo.value;
            break;
          default:
            paymentModeValue = this.authPaymentMode.default.value;
        }

        return {
          ...met,
          label: `${met.name}`, // Customize as needed
          value: paymentModeValue,
          // imageUrl: `${environment.CDN_BASE_URL}/images/${met.imageUrl}`,
        };
      }
    );
    // });
  }

  loadExistingPaymentMethods() {
    // this.subscription.add(
    //   this.lookupService.getVirtualCards().subscribe((cards) => {
    //     this.existingPaymentMethods = [
    //       {
    //         title: 'My Cards',
    //         options: cards.map((card) => ({
    //           ...card,
    //           label: `${card.cardType} - ${card.cardNumber}`, // Customize as needed
    //           value: `${card.cardType} - ${card.cardNumber}`, // or any unique identifier
    //         })),
    //       },
    //     ];
    //   })
    // );
  }

  handleSelectedPaymentMethod(selectedObj: any) {
    this.selectedPaymentMethod = selectedObj;
  }

  handlePaymentMethodPagination(): void {
    // Increase page size or page number and fetch more data
  }

  getFormattedAmount(label: string, amount: number): string {
    return `${label} - $${amount}`;
  }

  handleNewPaymentFormUpdate(updatedFormValues: any) {
    this.newPaymentMethod = updatedFormValues;
  }

  payAmount() {
    // Logic for payment processing (if any), 
    //pass response into modal as you close it also emit the value for external apps
    this.modalService.closeAll();
  }

  closeModal() {
    this.modalService.closeAll();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
