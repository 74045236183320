import { Injectable } from '@angular/core';
import { CONSTANT, StorageService } from '@seech-sub/core-ng';
import { AccountClientService, User } from '@seech/payment-ng';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  userInfo!: User;

  constructor(
    private storage: StorageService,
    private accountClientService: AccountClientService
  ) {}

  getUserInfo(): void {
    this.accountClientService.userAccountLogin().subscribe((info: User) => {
      this.persistUserInfo(info);
    });
  }

  private persistUserInfo(info: User) {
    this.storage.set(CONSTANT.BASIC_INFO, info);
  }
}
